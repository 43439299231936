import {createContext, useContext, useState, ReactNode} from 'react';

interface IScreenDimensions {
  availableHeight: number;
  statusBarHeight: number;
  topNavHeight: number;
  marginTopWithHeader: number;
}
interface IScreenDimensionsContext extends IScreenDimensions {
  setStatusBarHeight: (height: number) => void;
}
const ScreenDimensionsContext = createContext<
  IScreenDimensionsContext | undefined
>(undefined);

function ScreenDimensionsProvider({children}: {children: ReactNode}) {
  const [screenDimensions, setScreenDimensions] = useState<IScreenDimensions>({
    statusBarHeight: 0,
    availableHeight: 0,
    topNavHeight: 54,
    marginTopWithHeader: 54,
  });

  const setStatusBarHeight = (height: number) => {
    const maxHeight = document.documentElement.clientHeight;
    setScreenDimensions({
      ...screenDimensions,
      statusBarHeight: height,
      availableHeight: maxHeight - height,
      marginTopWithHeader: screenDimensions.topNavHeight + height,
    });
  };

  return (
    <ScreenDimensionsContext.Provider
      value={{...screenDimensions, setStatusBarHeight}}
    >
      {children}
    </ScreenDimensionsContext.Provider>
  );
}

function useDimensionsContext() {
  const context = useContext(ScreenDimensionsContext);
  if (context === undefined) {
    throw new Error(
      'useDimensionsContext must be used within a ScreenDimensionsContext',
    );
  }
  return context;
}

export {ScreenDimensionsProvider, useDimensionsContext};
